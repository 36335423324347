// export const APIURL = 'https://checkmong-api-uat.herokuapp.com';
// export const APIURL = 'http://127.0.0.1:8000';


//deploy
export const APIURL = 'https://d2ve6b8s3upvvd.cloudfront.net'
// export const APIURL = 'http://127.0.0.1:8000';


export const WEB_END_POINT = 'https://d28euih0sd6kjg.cloudfront.net'
// export const WEB_END_POINT = 'https://checkmong.com'


export const LIFFID = '1657454775-xV2qnykD'
