<template>
    <div>

    </div>
    </template>
    
    <script>
import {
    LIFFID
} from '@/constants'
    export default {
        name: "gateway",
        components: {
        },
        data() {
            return {
            };
        },
    
        // comment this on dev
        async beforeCreate() {
        // window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
        async created() {
            if (this.$route.query.goto == 'login') {
                this.$router.push("/passcode_check?goto=login");
            }
            if (this.$route.query.goto == 'dashboard_summary') {
                this.$router.push("/passcode_check?goto=dashboard_summary");
            }
            if (this.$route.query.goto == 'scan_accessdata') {
                this.$router.push("/passcode_check?goto=scan_accessdata");
            }
            if (this.$route.query.goto == 'scan_azq') {
                this.$router.push("/passcode_check?goto=scan_azq");
            }

        },
    
        watch: {
    
        },
        updated() {
        },
    
        methods: {     
        },
    };
    </script>
    
    <style>
    
    </style>
    